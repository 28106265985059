import React, { useContext, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import {
  Accordion,
  Badge,
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import DesktopWrapper from "../../../layout/desktop/DesktopWrapper";
import ArrowFlightSingle from "../../../assets/icons/arrow-flight-single.svg";
import ArrowFlightDouble from "../../../assets/icons/arrow-flight-double.svg";
import ClockIcon from "../../../assets/icons/clock.svg";
import { Link, redirect, useNavigate } from "react-router-dom";
import UserContext from "../../../services/user";
import moment from "moment";
import BeatLoader from "react-spinners/BeatLoader";
import { isMobile } from "react-device-detect";
import {
  AirlineImageConverter,
  AirportConverter,
  CityConverter,
} from "../../../config/helper/maskapai";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

var popupModal = (function () {
  var modal = null;
  return {
    openPopup(url) {
      modal = window
        .picoModal({
          content:
            '<iframe frameborder="0" style="height:90vh; width:100%;" src="' +
            url +
            '"></iframe>',
          width: "75%",
          closeButton: false,
          overlayClose: false,
          escCloses: false,
        })
        .show();
    },
    closePopup() {
      try {
        modal.close();
      } catch (e) {}
    },
  };
})();

const schema = yup
  .object({
    card_number: yup.string().required(),
    card_exp_month: yup.string().required(),
    card_exp_year: yup.string().required(),
    card_cvv: yup.string().required(),
  })
  .required();

function PaymentDesktopPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { payment, data, getMaster, getAllMaster, addMaster } =
    useContext(UserContext);
  const [dataDetail, setDataDetail] = useState({});
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [dataPayment, setDataPayment] = useState([]);
  const [modal, setModal] = useState({
    open: false,
    body: "",
    isSubmit: false,
    onSubmit: () => {},
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      card_number: "",
      card_exp_month: "",
      card_exp_year: "",
      card_cvv: "",
    },
  });

  const getCCToken = async (values) => {
    const newData = await addMaster("payment/got-token-cc", values);
    if (!newData?.err) {
      submitPayment(
        "credit_card",
        "credit_card",
        "credit card",
        newData?.data?.toke_id
      );
    }
  };

  const showCC = () => {
    setModal({
      open: true,
      type: "cc",
    });
  };

  const submitPayment = async (type, bank, category, ccToken) => {
    setLoadingPayment(true);
    const newData = await addMaster("voltras/select-payment-method", {
      invId: data?.payment?.invoiceNumber,
      paymentMethod: type,
      bankName: bank,
      ccToken: ccToken,
    });
    if (!newData?.err) {
      setLoadingPayment(false);
      data.paymentDetail = newData?.data;
      const newData2 = await payment(data);
      if (newData2) {
        if (category === "e-money") {
          if (isMobile) {
            window.open(
              newData2?.paymentDetail?.eWalletActions?.length > 0 &&
                newData2?.paymentDetail?.eWalletActions[1]?.url
            );
            redirect("/flights/receipt/" + newData2?.paymentDetail?._id);
          } else {
            setModal({
              open: true,
              type: "qr",
              redirect: "/flights/receipt/" + newData2?.paymentDetail?._id,
              body: (
                <Image
                  src={
                    newData2?.paymentDetail?.eWalletActions?.length > 0 &&
                    newData2?.paymentDetail?.eWalletActions[0]?.url
                  }
                  className="img-qr"
                />
              ),
            });
          }
        } else if (
          category === "credit card" &&
          newData2?.paymentDetail?.credit_card_redirect_url
        ) {
          var options = {
            performAuthentication: function (redirect_url) {
              popupModal.openPopup(redirect_url);
            },
            onSuccess: function (response) {
              toast.success(response?.status_message);
              popupModal.closePopup();
              navigate("/flights/receipt/" + newData2?.paymentDetail?._id);
            },
            onFailure: function (response) {
              toast.error(response?.status_message);
              popupModal.closePopup();
            },
            onPending: function (response) {
              toast.error(response?.status_message);
              popupModal.closePopup();
            },
          };
          window.MidtransNew3ds.authenticate(
            newData2?.paymentDetail?.credit_card_redirect_url,
            options
          );
        } else {
          navigate("/flights/receipt/" + newData2?.paymentDetail?._id);
        }
      }
    } else {
      setLoadingPayment(false);
    }
  };

  const getDataPayment = async () => {
    const newData = await getAllMaster("payment/payment-methods");
    if (newData) {
      const groups = newData?.data.reduce((groups, item) => {
        const group = groups[item.category] || [];
        group.push(item);
        groups[item.category] = group;
        return groups;
      }, {});
      setDataPayment(groups);
    }
  };

  useEffect(() => {
    if (data?.departure) {
      getDataPayment();
    } else {
      navigate(-1);
    }
  }, []);

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
    textAlign: "center",
  };

  return (
    <DesktopWrapper>
      {loadingPayment ? (
        <div className="my-5">
          <BeatLoader
            color={"#ff5b00"}
            loading={loadingPayment}
            cssOverride={override}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
          <div className="h4 text-center mt-4">Memproses Pembayaran</div>
        </div>
      ) : (
        <Container className="detail-product py-4">
          <Row>
            <Col sm="12" md="12" lg="12" xl="12" className="mb-4">
              <h4>{t("payment_confirmation")}</h4>
            </Col>
            <Col sm="12" md="12" lg="8" xl="8">
              {dataPayment &&
                Object.keys(dataPayment).map((item, index) => (
                  <div className="mb-4" key={index}>
                    <h6 className="fw-bold">
                      {item === "e-money" ? "QRIS" : item.toUpperCase()}
                    </h6>
                    <Card className="border-0 shadow-sm">
                      {dataPayment[item].map((subItem, subIndex) => (
                        <Card.Body key={subIndex} className="py-0">
                          {subItem.name !== "shopeePay" && (
                            <div
                              className="d-flex align-items-center justify-content-between py-1"
                              role="button"
                              onClick={() =>
                                item === "credit card"
                                  ? showCC()
                                  : submitPayment(
                                      subItem.value,
                                      subItem.name,
                                      item
                                    )
                              }
                            >
                              <div>
                                {subItem.name === "gopay"
                                  ? "QRIS"
                                  : subItem.name.toUpperCase()}
                              </div>
                              <Image
                                src={
                                  process.env.REACT_APP_ASSET_URL +
                                  "/" +
                                  subItem.picture
                                }
                                className="img-payment"
                              />
                            </div>
                          )}

                          {dataPayment[item]?.length > 1 &&
                            subIndex + 1 !== dataPayment[item]?.length && (
                              <hr className="my-0"></hr>
                            )}
                        </Card.Body>
                      ))}
                    </Card>
                  </div>
                ))}
            </Col>
            <Col sm="12" md="12" lg="4" xl="4">
              <Card className="border-0 shadow-sm mt-4 flight">
                <Card.Body className="p-3">
                  <h6 className="mb-4">
                    {t("booking")} {data?.payment?.invoiceNumber}
                  </h6>
                  <Tabs
                    defaultActiveKey="departure"
                    className="mb-3 payment-tab"
                  >
                    <Tab eventKey="departure" title="Departure">
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            {t("ticket_information")}
                          </Accordion.Header>
                          <Accordion.Body className="p-0">
                            <Card className="bg-light mb-3">
                              <Card.Body className="py-2 px-3">
                                <div className="mb-3 d-flex align-items-center justify-content-between small">
                                  <div className="py-2 d-flex align-items-center">
                                    <div>{data?.searchParams?.fromcity}</div>
                                    <Image
                                      src={ArrowFlightSingle}
                                      className="mx-3"
                                    />
                                    <div>{data?.searchParams?.tocity}</div>
                                  </div>
                                  <div className="d-flex">
                                    <Image src={ClockIcon} className="mx-1" />
                                    <div className="fw-light">
                                      {data?.departure?.durationhour}j{" "}
                                      {data?.departure?.durationminute}m
                                    </div>
                                  </div>
                                </div>
                                <div className="timeline">
                                  {data?.departure?.listflight?.flight?.length >
                                  1 ? (
                                    data?.departure?.listflight?.flight?.map(
                                      (item, index) => (
                                        <>
                                          <div className="timeline-flight">
                                            <div className="item">
                                              <div className="date-time">
                                                <div className="time">
                                                  {item?.departtime}
                                                </div>
                                                <div className="date">
                                                  {moment(
                                                    item?.departdate
                                                  ).format("ddd, DD MMM YYYY")}
                                                </div>
                                              </div>
                                              <div className="event">
                                                <div className="title">
                                                  {CityConverter(
                                                    item?.fromcity
                                                  )}{" "}
                                                  ({item?.fromcity})
                                                </div>
                                                <div className="description">
                                                  {AirportConverter(
                                                    item?.fromcity
                                                  )}
                                                </div>
                                                <div className="flight">
                                                  {AirlineImageConverter(
                                                    item?.airlinecode
                                                  )}
                                                  {item?.flightno} {" | "}
                                                  {data?.searchParams?.cabin
                                                    ?.replace(/-/g, " ")
                                                    .replace(
                                                      /(^\w{1})|(\s+\w{1})/g,
                                                      (letter) =>
                                                        letter?.toUpperCase()
                                                    )}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="item">
                                              <div className="date-time">
                                                <div className="time">
                                                  {item?.arrivetime}
                                                </div>
                                                <div className="date">
                                                  {moment(
                                                    item?.arrivaldate
                                                  ).format("ddd, DD MMM YYYY")}
                                                </div>
                                              </div>
                                              <div className="event">
                                                <div className="title">
                                                  {CityConverter(item?.tocity)}{" "}
                                                  ({item?.tocity})
                                                </div>
                                                <div className="description">
                                                  {AirportConverter(
                                                    item?.tocity
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          {index !==
                                            data?.departure?.listflight?.flight
                                              ?.length -
                                              1 && (
                                            <div className="w-100 text-center my-4">
                                              <div className="flight">
                                                Transit
                                              </div>
                                            </div>
                                          )}
                                        </>
                                      )
                                    )
                                  ) : (
                                    <div className="timeline-flight">
                                      <div className="item">
                                        <div className="date-time">
                                          <div className="time">
                                            {
                                              data?.departure?.listflight
                                                ?.flight?.departtime
                                            }
                                          </div>
                                          <div className="date">
                                            {moment(
                                              data?.departure?.listflight
                                                ?.flight?.departdate
                                            ).format("ddd, DD MMM YYYY")}
                                          </div>
                                        </div>
                                        <div className="event">
                                          <div className="title">
                                            {CityConverter(
                                              data?.departure?.listflight
                                                ?.flight?.fromcity
                                            )}{" "}
                                            (
                                            {
                                              data?.departure?.listflight
                                                ?.flight?.fromcity
                                            }
                                            )
                                          </div>
                                          <div className="description">
                                            {AirportConverter(
                                              data?.departure?.listflight
                                                ?.flight?.fromcity
                                            )}
                                          </div>
                                          <div className="flight">
                                            {AirlineImageConverter(
                                              data?.departure?.listflight
                                                ?.flight?.airlinecode
                                            )}
                                            {
                                              data?.departure?.listflight
                                                ?.flight?.flightno
                                            }
                                            {" | "}
                                            {data?.searchParams?.cabin
                                              ?.replace(/-/g, " ")
                                              .replace(
                                                /(^\w{1})|(\s+\w{1})/g,
                                                (letter) =>
                                                  letter?.toUpperCase()
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="item">
                                        <div className="date-time">
                                          <div className="time">
                                            {
                                              data?.departure?.listflight
                                                ?.flight?.arrivetime
                                            }
                                          </div>
                                          <div className="date">
                                            {moment(
                                              data?.departure?.listflight
                                                ?.flight?.arrivaldate
                                            ).format("ddd, DD MMM YYYY")}
                                          </div>
                                        </div>
                                        <div className="event">
                                          <div className="title">
                                            {CityConverter(
                                              data?.departure?.listflight
                                                ?.flight?.tocity
                                            )}{" "}
                                            (
                                            {
                                              data?.departure?.listflight
                                                ?.flight?.tocity
                                            }
                                            )
                                          </div>
                                          <div className="description">
                                            {AirportConverter(
                                              data?.departure?.listflight
                                                ?.flight?.tocity
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </Card.Body>
                            </Card>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>
                            Passenger Information
                          </Accordion.Header>
                          <Accordion.Body className="p-0">
                            {data?.pax?.map((item, index) => (
                              <Card className="bg-light mb-3" key={index}>
                                <Card.Body className="py-2 px-3">
                                  <div className="d-flex align-item-center justify-content-between">
                                    <div>
                                      {index + 1}. {item?.title}{" "}
                                      {item?.firstname} {item?.lastname}
                                    </div>
                                    <Badge className="bg-light-primary text-primary">
                                      {item?.type}
                                    </Badge>
                                  </div>
                                  <div className="my-2 small text-grey">
                                    <div className="mb-2">
                                      ID Number: {item.id}
                                    </div>
                                    <div>Born Date: {item.bod}</div>
                                  </div>
                                </Card.Body>
                              </Card>
                            ))}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Tab>
                    {data?.destination && (
                      <Tab eventKey="destination" title="Return">
                        <Accordion defaultActiveKey="0">
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              {t("ticket_information")}
                            </Accordion.Header>
                            <Accordion.Body className="p-0">
                              <Card className="bg-light mb-3">
                                <Card.Body className="py-2 px-3">
                                  <div className="mb-3 d-flex align-items-center justify-content-between small">
                                    <div className="py-2 d-flex align-items-center">
                                      <div>{data?.searchParams?.tocity}</div>
                                      <Image
                                        src={ArrowFlightSingle}
                                        className="mx-3"
                                      />
                                      <div>{data?.searchParams?.fromcity}</div>
                                    </div>
                                    <div className="d-flex">
                                      <Image src={ClockIcon} className="mx-1" />
                                      <div className="fw-light">
                                        {data?.destination?.durationhour}j{" "}
                                        {data?.destination?.durationminute}m
                                      </div>
                                    </div>
                                  </div>
                                  <div className="timeline">
                                    {data?.destination?.listflight?.flight
                                      ?.length > 1 ? (
                                      data?.destination?.listflight?.flight?.map(
                                        (item, index) => (
                                          <div key={index}>
                                            <div className="timeline-flight">
                                              <div className="item">
                                                <div className="date-time">
                                                  <div className="time">
                                                    {item?.departtime}
                                                  </div>
                                                  <div className="date">
                                                    {moment(
                                                      item?.departdate
                                                    ).format(
                                                      "ddd, DD MMM YYYY"
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="event">
                                                  <div className="title">
                                                    {CityConverter(
                                                      item?.fromcity
                                                    )}{" "}
                                                    ({item?.fromcity})
                                                  </div>
                                                  <div className="description">
                                                    {AirportConverter(
                                                      item?.fromcity
                                                    )}
                                                  </div>
                                                  <div className="flight">
                                                    {AirlineImageConverter(
                                                      item?.airlinecode
                                                    )}
                                                    {item?.flightno} {" | "}
                                                    {data?.searchParams?.cabin
                                                      ?.replace(/-/g, " ")
                                                      .replace(
                                                        /(^\w{1})|(\s+\w{1})/g,
                                                        (letter) =>
                                                          letter?.toUpperCase()
                                                      )}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="item">
                                                <div className="date-time">
                                                  <div className="time">
                                                    {item?.arrivetime}
                                                  </div>
                                                  <div className="date">
                                                    {moment(
                                                      item?.arrivaldate
                                                    ).format(
                                                      "ddd, DD MMM YYYY"
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="event">
                                                  <div className="title">
                                                    {CityConverter(
                                                      item?.arrival
                                                    )}{" "}
                                                    ({item?.arrival})
                                                  </div>
                                                  <div className="description">
                                                    {AirportConverter(
                                                      item?.arrival
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            {index !==
                                              data?.destination?.listflight
                                                ?.flight?.length -
                                                1 && (
                                              <div className="w-100 text-center my-4">
                                                <div className="flight">
                                                  Transit
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        )
                                      )
                                    ) : (
                                      <div className="timeline-flight">
                                        <div className="item">
                                          <div className="date-time">
                                            <div className="time">
                                              {
                                                data?.destination?.listflight
                                                  ?.flight?.departtime
                                              }
                                            </div>
                                            <div className="date">
                                              {moment(
                                                data?.destination?.listflight
                                                  ?.flight?.departdate
                                              ).format("ddd, DD MMM YYYY")}
                                            </div>
                                          </div>
                                          <div className="event">
                                            <div className="title">
                                              {CityConverter(
                                                data?.destination?.listflight
                                                  ?.flight?.fromcity
                                              )}{" "}
                                              (
                                              {
                                                data?.destination?.listflight
                                                  ?.flight?.fromcity
                                              }
                                              )
                                            </div>
                                            <div className="description">
                                              {AirportConverter(
                                                data?.destination?.listflight
                                                  ?.flight?.fromcity
                                              )}
                                            </div>
                                            <div className="flight">
                                              {AirlineImageConverter(
                                                data?.departure?.listflight
                                                  ?.flight?.airlinecode
                                              )}
                                              {
                                                data?.destination?.listflight
                                                  ?.flight?.flightno
                                              }
                                              {" | "}
                                              {data?.searchParams?.cabin
                                                ?.replace(/-/g, " ")
                                                .replace(
                                                  /(^\w{1})|(\s+\w{1})/g,
                                                  (letter) =>
                                                    letter?.toUpperCase()
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="item">
                                          <div className="date-time">
                                            <div className="time">
                                              {
                                                data?.destination?.listflight
                                                  ?.flight?.arrivetime
                                              }
                                            </div>
                                            <div className="date">
                                              {moment(
                                                data?.destination?.listflight
                                                  ?.flight?.arrivaldate
                                              ).format("ddd, DD MMM YYYY")}
                                            </div>
                                          </div>
                                          <div className="event">
                                            <div className="title">
                                              {CityConverter(
                                                data?.destination?.listflight
                                                  ?.flight?.tocity
                                              )}{" "}
                                              (
                                              {
                                                data?.destination?.listflight
                                                  ?.flight?.tocity
                                              }
                                              )
                                            </div>
                                            <div className="description">
                                              {AirportConverter(
                                                data?.destination?.listflight
                                                  ?.flight?.tocity
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </Card.Body>
                              </Card>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>
                              Passenger Information
                            </Accordion.Header>
                            <Accordion.Body className="p-0">
                              {data?.pax?.map((item, index) => (
                                <Card className="bg-light mb-3" key={index}>
                                  <Card.Body className="py-2 px-3">
                                    <div className="d-flex align-item-center justify-content-between">
                                      <div>
                                        {index + 1}. {item?.title}{" "}
                                        {item?.firstname} {item?.lastname}
                                      </div>
                                      <Badge className="bg-light-primary text-primary">
                                        {item?.type}
                                      </Badge>
                                    </div>
                                    <div className="my-2 small text-grey">
                                      <div className="mb-2">
                                        ID Number: {item.id}
                                      </div>
                                      <div>Born Date: {item.bod}</div>
                                    </div>
                                  </Card.Body>
                                </Card>
                              ))}
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Tab>
                    )}
                    <Tab eventKey="price" title={t("price")}>
                      <h6>{dataDetail?.generalInformation?.name}</h6>
                      <div className="small fw-light mt-3">
                        <div className="pb-3">{t("ticket_price")}</div>
                        <ul className="text-grey">
                          <li className="pb-2">
                            <div className="d-flex justify-content-between">
                              <div>Passanger</div>
                              <div>
                                {new Intl.NumberFormat("id-ID", {
                                  style: "currency",
                                  currency: "IDR",
                                  minimumFractionDigits: 0,
                                }).format(data?.total)}{" "}
                                x{" "}
                                {parseInt(data?.searchParams?.adult) +
                                  parseInt(data?.searchParams?.child) +
                                  parseInt(data?.searchParams?.infant)}
                              </div>
                            </div>
                          </li>
                          <li className="pb-2">
                            <div className="d-flex justify-content-between">
                              <div>Insurance</div>
                              <div>
                                {new Intl.NumberFormat("id-ID", {
                                  style: "currency",
                                  currency: "IDR",
                                  minimumFractionDigits: 0,
                                }).format(
                                  parseInt(data?.insurance) /
                                    parseInt(data?.searchParams?.adult) +
                                    parseInt(data?.searchParams?.child) +
                                    parseInt(data?.searchParams?.infant)
                                )}{" "}
                                x{" "}
                                {parseInt(data?.searchParams?.adult) +
                                  parseInt(data?.searchParams?.child) +
                                  parseInt(data?.searchParams?.infant)}
                              </div>
                            </div>
                          </li>
                        </ul>
                        <hr className="line-information"></hr>
                        <div className="d-flex align-items-center justify-content-between fw-normal">
                          <div>{t("total_payment")}</div>
                          <div className="text-primary h6">
                            {new Intl.NumberFormat("id-ID", {
                              style: "currency",
                              currency: "IDR",
                              minimumFractionDigits: 0,
                            }).format(data?.payment?.grossAmount)}
                          </div>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Modal
            show={modal.open && modal.type === "qr"}
            onHide={() =>
              setModal({
                open: false,
                body: "",
              })
            }
            centered
            className="bg-grey bg-opacity-75"
          >
            <Modal.Body>
              <div className="mb-5">{modal.body}</div>
              <Button
                className="text-white w-100"
                variant="grey"
                onClick={() => {
                  navigate(modal.redirect);
                  setModal({
                    open: false,
                    body: "",
                  });
                }}
              >
                Done
              </Button>
            </Modal.Body>
          </Modal>

          <Modal
            show={modal.open && modal.type === "cc"}
            onHide={() =>
              setModal({
                open: false,
                type: "",
              })
            }
            centered
            className="bg-grey bg-opacity-75"
          >
            <Modal.Body>
              <div className="mb-5">
                <Row>
                  <Col sm="12" md="12" lg="12" xl="12">
                    <div className="mb-3">
                      <Form.Control
                        className="control-input"
                        placeholder="Card Number"
                        {...register("card_number")}
                      />
                      <small className="text-danger">
                        {errors.card_number?.message}
                      </small>
                    </div>
                  </Col>
                  <Col sm="12" md="4" lg="4" xl="4">
                    <div className="mb-3">
                      <Form.Control
                        className="control-input"
                        placeholder="Card Exp Month"
                        {...register("card_exp_month")}
                      />
                      <small className="text-danger">
                        {errors.card_exp_month?.message}
                      </small>
                    </div>
                  </Col>
                  <Col sm="12" md="4" lg="4" xl="4">
                    <div className="mb-3">
                      <Form.Control
                        className="control-input"
                        placeholder="Card Exp Year"
                        {...register("card_exp_year")}
                      />
                      <small className="text-danger">
                        {errors.card_exp_year?.message}
                      </small>
                    </div>
                  </Col>
                  <Col sm="12" md="4" lg="4" xl="4">
                    <div className="mb-3">
                      <Form.Control
                        className="control-input"
                        placeholder="cvv"
                        {...register("card_cvv")}
                      />
                      <small className="text-danger">
                        {errors.card_cvv?.message}
                      </small>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="w-100 d-flex">
                <Button
                  className="text-white w-100"
                  variant="grey"
                  onClick={() =>
                    setModal({
                      open: false,
                      type: "",
                    })
                  }
                >
                  Close
                </Button>
                <Button
                  className="text-white w-100"
                  onClick={handleSubmit(getCCToken)}
                >
                  Submit
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        </Container>
      )}
    </DesktopWrapper>
  );
}
export default PaymentDesktopPage;
