import React, { useContext, useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Image,
  Modal,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import UserContext from "../../../../services/user";
import { SkeletonCustom } from "../../../../component/global/skeleton";
import FlightTab from "./flightTab";
import FlightInfo from "./flightInfo";
import ItemFlight from "./itemFlight";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import ClockIcon from "../../../../assets/icons/clock.svg";
import ArrowFlightSingle from "../../../../assets/icons/arrow-flight-single.svg";
import ArrowRightIcon from "../../../../assets/icons/arrow-right.svg";
import MaskapaiData from "../../../../assets/maskapai.json";
import {
  AirlineConverter,
  AirlineImageConverter,
  AirportConverter,
  CityConverter,
} from "../../../../config/helper/maskapai";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import FilterFlight from "./FilterFlight";

const defaultFilter = {
  type: ["langsung", "1 transit", "2 transit"],
  startPrice: 100000,
  endPrice: 20000000,
  maskapai: MaskapaiData?.map((item) => item?.code),
  startTime: ["quarter1", "quarter2", "quarter3", "quarter4"],
  endTime: ["quarter1", "quarter2", "quarter3", "quarter4"],
  facility: [],
};

function ListFlight(params) {
  const { t } = useTranslation();
  const [key, setKey] = useState("");
  const { loading, addMaster, payment, loadingAction } =
    useContext(UserContext);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState();
  const [filterFlight, setFilterFlight] = useState(defaultFilter);
  const [firstChoose, setFirstChoose] = useState(null);
  const [secondChoose, setSecondChoose] = useState(null);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [total, setTotal] = useState(0);
  const [type, setType] = useState(0);
  const [trigger, setTrigger] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [modal, setModal] = useState({
    open: false,
    type: "",
  });
  const [showPrice, setShowPrice] = useState(false);
  const [dataShow, setDataShow] = useState({ 0: [], 1: [] });

  const checkTime = (time, type) => {
    const newTime = moment(time, "HH:mm");
    const quarter1Start = moment("00:00", "HH:mm");
    const quarter1End = moment("05:59", "HH:mm");
    const quarter2Start = moment("06:00", "HH:mm");
    const quarter2End = moment("11:59", "HH:mm");
    const quarter3Start = moment("12:00", "HH:mm");
    const quarter3End = moment("17:59", "HH:mm");
    const quarter4Start = moment("18:00", "HH:mm");
    const quarter4End = moment("23:59", "HH:mm");

    if (
      newTime.isBetween(quarter1Start, quarter1End) &&
      type.includes("quarter1")
    ) {
      return true;
    } else if (
      newTime.isBetween(quarter2Start, quarter2End) &&
      type.includes("quarter2")
    ) {
      return true;
    } else if (
      newTime.isBetween(quarter3Start, quarter3End) &&
      type.includes("quarter3")
    ) {
      return true;
    } else if (
      newTime.isBetween(quarter4Start, quarter4End) &&
      type.includes("quarter4")
    ) {
      return true;
    }
    return false;
  };

  const getData = async () => {
    loadingAction();
    const newData = await addMaster(`voltras/got-flight-availability`, filter);
    if (newData) {
      const newData1 = newData.data[0]?.listitem?.item?.filter((item) => {
        return !isNaN(
          Array.isArray(item?.listclassgroup?.classgroup)
            ? item?.listclassgroup?.classgroup?.reduce(
                (accumulator, current) =>
                  parseInt(accumulator?.class?.fare) +
                  parseInt(accumulator?.class?.servicefee) +
                  (parseInt(current?.class?.fare) +
                    parseInt(current?.class?.servicefee))
              )
            : parseInt(item?.listclassgroup?.classgroup?.class?.fare) +
                parseInt(item?.listclassgroup?.classgroup?.class?.servicefee)
        );
      });
      const newData2 = newData.data[1]?.listitem?.item?.filter((item) => {
        return !isNaN(
          Array.isArray(item?.listclassgroup?.classgroup)
            ? item?.listclassgroup?.classgroup?.reduce(
                (accumulator, current) =>
                  parseInt(accumulator?.class?.fare) +
                  parseInt(accumulator?.class?.servicefee) +
                  (parseInt(current?.class?.fare) +
                    parseInt(current?.class?.servicefee))
              )
            : parseInt(item?.listclassgroup?.classgroup?.class?.fare) +
                parseInt(item?.listclassgroup?.classgroup?.class?.servicefee)
        );
      });

      let ArrNew = [
        { $: { type: "departure" }, listitem: { item: newData1 } },
        { $: { type: "return" }, listitem: { item: newData2 } },
      ];
      setData(ArrNew);
    }
  };

  const onSubmit = async () => {
    if (localStorage.getItem("role") !== "member") {
      toast.error("Please Login. You need to login to access this action.");
      navigate("/login", { state: { prev: true } });
    } else {
      const data = {
        departure: firstChoose,
        destination: secondChoose,
        searchParams: filter,
        total: total,
      };
      const newData = await payment(data);
      if (newData) {
        setModalConfirm(false);
        navigate("/flights/booking");
      }
    }
  };

  useEffect(() => {
    if (filter) {
      getData();
    }
  }, [filter]);

  useEffect(() => {
    if (
      searchParams.get("departure") &&
      searchParams.get("destination") &&
      searchParams.get("startDate") &&
      searchParams.get("endDate") &&
      searchParams.get("class") &&
      searchParams.get("adult") &&
      searchParams.get("child") &&
      searchParams.get("infant")
    ) {
    }
    setFilter({
      fromcity: searchParams.get("departure"),
      tocity: searchParams.get("destination"),
      departdate: moment(searchParams.get("startDate")).format("DD-MMM-YYYY"),
      returndate: searchParams.get("endDate")
        ? moment(searchParams.get("endDate")).format("DD-MMM-YYYY")
        : null,
      listairline: {
        airline: MaskapaiData?.map((item) => item?.code),
      },
      cabin: searchParams.get("class"),
      adult: searchParams.get("adult"),
      child: searchParams.get("child"),
      infant: searchParams.get("infant"),
      cheapestclass: true,
    });
  }, [searchParams]);

  useEffect(() => {
    if (firstChoose && searchParams.get("endDate")) {
      setType(1);
    }
  }, [firstChoose]);

  useEffect(() => {
    if (searchParams.get("endDate") && !secondChoose) {
      setTrigger(false);
    }
    if (
      firstChoose &&
      (secondChoose || !searchParams.get("endDate")) &&
      trigger
    ) {
      setModalConfirm(true);
      setTrigger(false);
      let first =
        firstChoose?.listclassgroup?.classgroup?.length > 1
          ? firstChoose?.listclassgroup?.classgroup?.reduce(
              (accumulator, current) =>
                parseInt(accumulator?.class?.fare) +
                parseInt(accumulator?.class?.servicefee) +
                (parseInt(current?.class?.fare) +
                  parseInt(current?.class?.servicefee))
            )
          : parseInt(firstChoose?.listclassgroup?.classgroup?.class?.fare) +
            parseInt(
              firstChoose?.listclassgroup?.classgroup?.class?.servicefee
            );
      let second =
        secondChoose?.listclassgroup?.classgroup?.length > 1
          ? secondChoose?.listclassgroup?.classgroup?.reduce(
              (accumulator, current) =>
                parseInt(accumulator?.class?.fare) +
                parseInt(accumulator?.class?.servicefee) +
                (parseInt(current?.class?.fare) +
                  parseInt(current?.class?.servicefee))
            )
          : parseInt(secondChoose?.listclassgroup?.classgroup?.class?.fare) +
            parseInt(
              firstChoose?.listclassgroup?.classgroup?.class?.servicefee
            );
      setTotal(parseInt(first) + (second ? parseInt(second) : 0));
    }
  }, [trigger]);

  useEffect(() => {
    setDataShow({
      0: data[0]?.listitem?.item?.filter((item) => {
        let resultType = false;
        let resultPrice = false;
        let resultMaskapai = false;
        let resultStartTime = false;
        let resultEndTime = false;
        let resultFacilityBaggage = true;
        let resultFacilityMeal = true;

        if (
          filterFlight?.type.includes("langsung") &&
          !Array.isArray(item?.listflight?.flight)
        ) {
          resultType = true;
        } else if (
          filterFlight?.type.includes("1 transit") &&
          item?.listflight?.flight.length === 1
        ) {
          resultType = true;
        } else if (
          filterFlight?.type.includes("2 transit") &&
          item?.listflight?.flight.length > 1
        ) {
          resultType = true;
        }

        let price = Array.isArray(item?.listclassgroup?.classgroup)
          ? item?.listclassgroup?.classgroup?.reduce(
              (accumulator, current) =>
                parseInt(accumulator?.class?.fare) +
                parseInt(accumulator?.class?.servicefee) +
                (parseInt(current?.class?.fare) +
                  parseInt(current?.class?.servicefee))
            )
          : parseInt(item?.listclassgroup?.classgroup?.class?.fare) +
            parseInt(item?.listclassgroup?.classgroup?.class?.servicefee);

        if (
          price >= filterFlight?.startPrice &&
          price <= filterFlight?.endPrice
        ) {
          resultPrice = true;
        }

        if (
          Array?.isArray(item?.listflight?.flight) &&
          item?.listflight?.flight?.find((o) =>
            filterFlight?.maskapai?.includes(o.flightno.substring(0, 2))
          )
        ) {
          resultMaskapai = true;
        } else if (
          !Array?.isArray(item?.listflight?.flight) &&
          filterFlight?.maskapai?.includes(
            item?.listflight?.flight?.flightno.substring(0, 2)
          )
        ) {
          resultMaskapai = true;
        }

        if (
          Array?.isArray(item?.listflight?.flight) &&
          checkTime(
            item?.listflight?.flight?.[0]?.departtime,
            filterFlight?.startTime
          )
        ) {
          resultStartTime = true;
        } else if (
          !Array?.isArray(item?.listflight?.flight) &&
          checkTime(
            item?.listflight?.flight?.departtime,
            filterFlight?.startTime
          )
        ) {
          resultStartTime = true;
        }

        if (
          Array?.isArray(item?.listflight?.flight) &&
          checkTime(
            item?.listflight?.flight?.[item?.listflight?.flight?.length - 1]
              ?.arrivetime,
            filterFlight?.endTime
          )
        ) {
          resultEndTime = true;
        } else if (
          !Array?.isArray(item?.listflight?.flight) &&
          checkTime(item?.listflight?.flight?.arrivetime, filterFlight?.endTime)
        ) {
          resultEndTime = true;
        }

        if (Array?.isArray(item?.listclassgroup?.classgroup)) {
          if (
            filterFlight?.facility.includes("baggage") &&
            item?.listclassgroup?.classgroup?.find(
              (o) => parseInt(o?.class?.baggagekilos) <= 0
            )
          ) {
            resultFacilityBaggage = false;
          }
        } else if (!Array?.isArray(item?.listclassgroup?.classgroup)) {
          if (
            filterFlight?.facility.includes("baggage") &&
            parseInt(item?.listclassgroup?.classgroup?.class?.baggagekilos) <= 0
          ) {
            resultFacilityBaggage = false;
          }
        }

        if (Array?.isArray(item?.listflight?.flight)) {
          if (
            filterFlight?.facility.includes("meal") &&
            item?.listflight?.flight?.find((o) => o?.meal !== "true")
          ) {
            resultFacilityMeal = false;
          }
        } else if (!Array?.isArray(item?.listflight?.flight)) {
          if (
            filterFlight?.facility.includes("meal") &&
            item?.listflight?.flight?.meal !== "true"
          ) {
            resultFacilityMeal = false;
          }
        }

        return (
          resultType &&
          resultPrice &&
          resultMaskapai &&
          resultStartTime &&
          resultEndTime &&
          resultFacilityBaggage &&
          resultFacilityMeal
        );
      }),
      1: data[1]?.listitem?.item?.filter((item) => {
        let resultType = false;
        let resultPrice = false;
        let resultMaskapai = false;
        let resultStartTime = false;
        let resultEndTime = false;
        let resultFacilityBaggage = true;
        let resultFacilityMeal = true;

        if (
          filterFlight?.type.includes("langsung") &&
          !Array.isArray(item?.listflight?.flight)
        ) {
          resultType = true;
        } else if (
          filterFlight?.type.includes("1 transit") &&
          item?.listflight?.flight.length === 1
        ) {
          resultType = true;
        } else if (
          filterFlight?.type.includes("2 transit") &&
          item?.listflight?.flight.length > 1
        ) {
          resultType = true;
        }

        let price = Array.isArray(item?.listclassgroup?.classgroup)
          ? item?.listclassgroup?.classgroup?.reduce(
              (accumulator, current) =>
                parseInt(accumulator?.class?.fare) +
                parseInt(accumulator?.class?.servicefee) +
                (parseInt(current?.class?.fare) +
                  parseInt(current?.class?.servicefee))
            )
          : parseInt(item?.listclassgroup?.classgroup?.class?.fare) +
            parseInt(item?.listclassgroup?.classgroup?.class?.servicefee);

        if (
          price >= filterFlight?.startPrice &&
          price <= filterFlight?.endPrice
        ) {
          resultPrice = true;
        }

        if (
          Array?.isArray(item?.listflight?.flight) &&
          item?.listflight?.flight?.find((o) =>
            filterFlight?.maskapai?.includes(o.flightno.substring(0, 2))
          )
        ) {
          resultMaskapai = true;
        } else if (
          !Array?.isArray(item?.listflight?.flight) &&
          filterFlight?.maskapai?.includes(
            item?.listflight?.flight?.flightno.substring(0, 2)
          )
        ) {
          resultMaskapai = true;
        }

        if (
          Array?.isArray(item?.listflight?.flight) &&
          checkTime(
            item?.listflight?.flight?.[0]?.departtime,
            filterFlight?.startTime
          )
        ) {
          resultStartTime = true;
        } else if (
          !Array?.isArray(item?.listflight?.flight) &&
          checkTime(
            item?.listflight?.flight?.departtime,
            filterFlight?.startTime
          )
        ) {
          resultStartTime = true;
        }

        if (
          Array?.isArray(item?.listflight?.flight) &&
          checkTime(
            item?.listflight?.flight?.[item?.listflight?.flight?.length - 1]
              ?.arrivetime,
            filterFlight?.endTime
          )
        ) {
          resultEndTime = true;
        } else if (
          !Array?.isArray(item?.listflight?.flight) &&
          checkTime(item?.listflight?.flight?.arrivetime, filterFlight?.endTime)
        ) {
          resultEndTime = true;
        }

        if (Array?.isArray(item?.listclassgroup?.classgroup)) {
          if (
            filterFlight?.facility.includes("baggage") &&
            item?.listclassgroup?.classgroup?.find(
              (o) => parseInt(o?.class?.baggagekilos) <= 0
            )
          ) {
            resultFacilityBaggage = false;
          }
        } else if (!Array?.isArray(item?.listclassgroup?.classgroup)) {
          if (
            filterFlight?.facility.includes("baggage") &&
            parseInt(item?.listclassgroup?.classgroup?.class?.baggagekilos) <= 0
          ) {
            resultFacilityBaggage = false;
          }
        }

        if (Array?.isArray(item?.listflight?.flight)) {
          if (
            filterFlight?.facility.includes("meal") &&
            item?.listflight?.flight?.find((o) => o?.meal !== "true")
          ) {
            resultFacilityMeal = false;
          }
        } else if (!Array?.isArray(item?.listflight?.flight)) {
          if (
            filterFlight?.facility.includes("meal") &&
            item?.listflight?.flight?.meal !== "true"
          ) {
            resultFacilityMeal = false;
          }
        }

        return (
          resultType &&
          resultPrice &&
          resultMaskapai &&
          resultStartTime &&
          resultEndTime &&
          resultFacilityBaggage &&
          resultFacilityMeal
        );
      }),
    });
  }, [filterFlight, data]);

  return (
    <Container>
      <div className="d-flex align-items-between pb-5">
        <FlightInfo data={filter} type={type} />
      </div>
      <Row className="mb-5 align-items-start">
        <Col sm="12" md="12" lg="4" xl="3">
          <FlightTab data={filter} type={type} />
          <FilterFlight
            filterFlight={filterFlight}
            setFilterFlight={setFilterFlight}
            defaultFilter={defaultFilter}
          />
        </Col>
        <Col sm="12" md="12" lg="8" xl="9">
          {loading ? (
            <SkeletonCustom height="100px" />
          ) : data ? (
            dataShow[type]?.length > 0 ? (
              dataShow[type]?.map(
                (item, index) =>
                  item?.listclassgroup?.classgroup?.class?.fare !== "0" && (
                    <ItemFlight
                      data={item}
                      dataBase={filter}
                      type={type}
                      choose={type === 0 ? firstChoose : secondChoose}
                      setChoose={type === 0 ? setFirstChoose : setSecondChoose}
                      setTrigger={setTrigger}
                      key={index}
                    />
                  )
              )
            ) : (
              "Data Tidak Ditemukan"
            )
          ) : (
            "Data Tidak Ditemukan"
          )}
        </Col>
      </Row>

      <Modal
        show={modalConfirm}
        onHide={() => setModalConfirm(false)}
        centered
        className="bg-grey bg-opacity-75"
        size="xl"
      >
        <Modal.Body>
          <div className="mb-3">Your Flights</div>
          {[firstChoose, secondChoose].map(
            (item, key) =>
              item && (
                <Row
                  className={
                    "align-item-center justify-content-center py-3 " +
                    (key === 0 && secondChoose && "border-bottom")
                  }
                  key={key}
                >
                  <Col xs="12" sm="12" md="12" lg="3" xl="3">
                    <div className="fw-bold mb-2">
                      {key === 0 ? "Departure" : "Return"}
                    </div>
                    <div className="text-grey">
                      {moment(
                        item?.listflight?.flight?.length > 1
                          ? item?.listflight?.flight[key]?.departdate
                          : item?.listflight?.flight?.departdate
                      ).format("ddd, DD MMM YYYY")}
                    </div>
                  </Col>
                  <Col xs="12" sm="12" md="12" lg="4" xl="4">
                    <div className="d-flex align-items-center h-100">
                      {/* <Image src={FlightLionAir} /> */}
                      <div className="ms-2 fs-5">
                        {item?.listflight?.flight?.length > 1
                          ? item?.listflight?.flight?.map((item, index) => {
                              if (index > 0) {
                                if (
                                  item.airlinecode !==
                                  params?.data?.listflight?.flight?.[index - 1]
                                    ?.airlinecode
                                ) {
                                  return AirlineConverter(
                                    item.flightno.substring(0, 2)
                                  );
                                }
                              } else {
                                return AirlineConverter(
                                  item.flightno.substring(0, 2)
                                );
                              }
                            })
                          : AirlineConverter(
                              item?.listflight?.flight?.flightno.substring(0, 2)
                            )}
                        {/* {AirlineConverter(
                          item?.listflight?.flight?.length > 1
                            ? item?.listflight?.flight[0]?.flightno.substring(
                                0,
                                2
                              )
                            : item?.listflight?.flight?.flightno.substring(0, 2)
                        )} */}
                      </div>
                    </div>
                  </Col>
                  <Col xs="12" sm="12" md="12" lg="5" xl="5" className="d-flex">
                    <div className="d-flex text-center ms-auto flight-schedule">
                      <div className="me-4 first">
                        <div className="fw-bold">
                          {item?.listflight?.flight?.length > 1
                            ? item?.listflight?.flight[0]?.departtime
                            : item?.listflight?.flight?.departtime}
                        </div>
                        <Badge className="mt-2" bg="grey">
                          {item?.listflight?.flight?.length > 1
                            ? item?.listflight?.flight[0]?.fromcity
                            : item?.listflight?.flight?.fromcity}
                        </Badge>
                      </div>
                      <div className="me-4 fw-lighter second">
                        <div>
                          {item?.durationhour}j {item?.durationminute}m
                        </div>
                        <div className="line-flight"></div>
                        <div className="mt-2">
                          {item?.listflight?.flight?.length > 1
                            ? item?.listflight?.flight?.length + " Transit"
                            : "Direct"}
                        </div>
                      </div>
                      <div className="third">
                        <div className="fw-bold">
                          {item?.listflight?.flight?.length > 1
                            ? item?.listflight?.flight[
                                item?.listflight?.flight?.length - 1
                              ]?.arrivetime
                            : item?.listflight?.flight?.arrivetime}{" "}
                          <span className="fw-light small">
                            {item?.listflight?.flight?.length > 1 &&
                              item?.listflight?.flight[0]?.arrivaldate !==
                                item?.listflight?.flight[
                                  item?.listflight?.flight?.length - 1
                                ]?.arrivaldate &&
                              "(+1 D)"}
                          </span>
                        </div>
                        <Badge className="mt-2" bg="grey">
                          {item?.listflight?.flight?.length > 1
                            ? item?.listflight?.flight[
                                item?.listflight?.flight?.length - 1
                              ]?.tocity
                            : item?.listflight?.flight?.tocity}
                        </Badge>
                      </div>
                    </div>
                  </Col>
                </Row>
              )
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-between">
          <div className="text-primary d-flex">
            <div
              className="me-3"
              role="button"
              onClick={() => setModal({ open: true, type: "all" })}
            >
              Flight Details
            </div>
            <div
              className="me-3"
              role="button"
              onClick={() => setShowPrice(true)}
            >
              Price Details
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div className="text-primary small me-3">
              <span className="fs-6">
                {new Intl.NumberFormat("id-ID", {
                  style: "currency",
                  currency: "IDR",
                  minimumFractionDigits: 0,
                }).format(total)}
              </span>
              /pax
            </div>
            <Button
              type="primary"
              className="text-white"
              onClick={() => onSubmit()}
            >
              Book
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showPrice}
        onHide={() => setShowPrice(false)}
        size="sm"
        centered
        className="bg-grey bg-opacity-75"
      >
        <Modal.Body>
          <div className="mb-4">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="text-muted">Price</div>
              <div>
                {new Intl.NumberFormat("id-ID", {
                  style: "currency",
                  currency: "IDR",
                  minimumFractionDigits: 0,
                }).format(total)}{" "}
                x{" "}
                {parseInt(filter?.adult) +
                  parseInt(filter?.child) +
                  parseInt(filter?.infant)}
              </div>
            </div>
            <hr></hr>
            <div className="d-flex align-items-center justify-content-between">
              <div className="fw-bold">{t("total_price")}</div>
              <div className="fw-bold">
                {new Intl.NumberFormat("id-ID", {
                  style: "currency",
                  currency: "IDR",
                  minimumFractionDigits: 0,
                }).format(
                  total *
                    (parseInt(filter?.adult) +
                      parseInt(filter?.child) +
                      parseInt(filter?.infant))
                )}
              </div>
            </div>
          </div>
          <Button
            className="text-white w-100"
            onClick={() => setShowPrice(false)}
          >
            Close
          </Button>
        </Modal.Body>
      </Modal>

      <Modal
        show={modal?.open}
        onHide={() => setModal({ open: false, type: "" })}
        centered
        className="bg-grey bg-opacity-75"
      >
        <Modal.Body>
          <div className="mb-4">
            <div className="text-muted mb-3">Flight Detail</div>
            <div>
              {(modal?.type === "departure" || modal?.type === "all") && (
                <div>
                  <div className="mb-3 d-flex align-items-center justify-content-between small">
                    <div className="py-2 d-flex align-items-center">
                      <div>{filter?.fromcity}</div>
                      <Image src={ArrowFlightSingle} className="mx-3" />
                      <div>{filter?.tocity}</div>
                    </div>
                    <div className="d-flex">
                      <Image src={ClockIcon} className="mx-1" />
                      <div className="fw-light">
                        {firstChoose?.durationhour}j{" "}
                        {firstChoose?.durationminute}m
                      </div>
                    </div>
                  </div>
                  <div className="timeline">
                    {firstChoose?.listflight?.flight?.length > 1 ? (
                      firstChoose?.listflight?.flight?.map((item, index) => (
                        <>
                          <div className="timeline-flight">
                            <div className="item">
                              <div className="date-time">
                                <div className="time">{item?.departtime}</div>
                                <div className="date">
                                  {moment(item?.departdate).format(
                                    "ddd, DD MMM YYYY"
                                  )}
                                </div>
                              </div>
                              <div className="event">
                                <div className="title">
                                  {CityConverter(item?.fromcity)} (
                                  {item?.fromcity})
                                </div>
                                <div className="description">
                                  {AirportConverter(item?.fromcity)}
                                </div>
                                <div className="flight">
                                  {AirlineImageConverter(item?.airlinecode)}
                                  {item?.flightno} {" | "}
                                  {filter?.cabin
                                    ?.replace(/-/g, " ")
                                    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                                      letter?.toUpperCase()
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className="item">
                              <div className="date-time">
                                <div className="time">{item?.arrivetime}</div>
                                <div className="date">
                                  {moment(item?.arrivaldate).format(
                                    "ddd, DD MMM YYYY"
                                  )}
                                </div>
                              </div>
                              <div className="event">
                                <div className="title">
                                  {CityConverter(item?.tocity)} ({item?.tocity})
                                </div>
                                <div className="description">
                                  {AirportConverter(item?.tocity)}
                                </div>
                              </div>
                            </div>
                          </div>
                          {index !==
                            firstChoose?.listflight?.flight?.length - 1 && (
                            <div className="w-100 text-center my-4">
                              <div className="flight">Transit</div>
                            </div>
                          )}
                        </>
                      ))
                    ) : (
                      <div className="timeline-flight">
                        <div className="item">
                          <div className="date-time">
                            <div className="time">
                              {firstChoose?.listflight?.flight?.departtime}
                            </div>
                            <div className="date">
                              {moment(
                                firstChoose?.listflight?.flight?.departdate
                              ).format("ddd, DD MMM YYYY")}
                            </div>
                          </div>
                          <div className="event">
                            <div className="title">
                              {CityConverter(
                                firstChoose?.listflight?.flight?.fromcity
                              )}{" "}
                              ({firstChoose?.listflight?.flight?.fromcity})
                            </div>
                            <div className="description">
                              {AirportConverter(
                                firstChoose?.listflight?.flight?.fromcity
                              )}
                            </div>
                            <div className="flight">
                              {AirlineImageConverter(
                                firstChoose?.listflight?.flight?.airlinecode
                              )}
                              {firstChoose?.listflight?.flight?.flightno}
                              {" | "}
                              {filter?.cabin
                                ?.replace(/-/g, " ")
                                .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                                  letter?.toUpperCase()
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="item">
                          <div className="date-time">
                            <div className="time">
                              {firstChoose?.listflight?.flight?.arrivetime}
                            </div>
                            <div className="date">
                              {moment(
                                firstChoose?.listflight?.flight?.arrivaldate
                              ).format("ddd, DD MMM YYYY")}
                            </div>
                          </div>
                          <div className="event">
                            <div className="title">
                              {CityConverter(
                                firstChoose?.listflight?.flight?.tocity
                              )}{" "}
                              ({firstChoose?.listflight?.flight?.tocity})
                            </div>
                            <div className="description">
                              {AirportConverter(
                                firstChoose?.listflight?.flight?.tocity
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {(modal?.type === "destination" || modal?.type === "all") &&
                secondChoose && (
                  <div>
                    <div className="mb-3 d-flex align-items-center justify-content-between small">
                      <div className="py-2 d-flex align-items-center">
                        <div>{filter?.tocity}</div>
                        <Image src={ArrowFlightSingle} className="mx-3" />
                        <div>{filter?.fromcity}</div>
                      </div>
                      <div className="d-flex">
                        <Image src={ClockIcon} className="mx-1" />
                        <div className="fw-light">
                          {secondChoose?.durationhour}j{" "}
                          {secondChoose?.durationminute}m
                        </div>
                      </div>
                    </div>
                    <div className="timeline">
                      {secondChoose?.listflight?.flight?.length > 1 ? (
                        secondChoose?.listflight?.flight?.map((item, index) => (
                          <div key={index}>
                            <div className="timeline-flight">
                              <div className="item">
                                <div className="date-time">
                                  <div className="time">{item?.departtime}</div>
                                  <div className="date">
                                    {moment(item?.departdate).format(
                                      "ddd, DD MMM YYYY"
                                    )}
                                  </div>
                                </div>
                                <div className="event">
                                  <div className="title">
                                    {CityConverter(item?.fromcity)} (
                                    {item?.fromcity})
                                  </div>
                                  <div className="description">
                                    {AirportConverter(item?.fromcity)}
                                  </div>
                                  <div className="flight">
                                    {AirlineImageConverter(item?.airlinecode)}
                                    {item?.flightno} {" | "}
                                    {filter?.cabin
                                      ?.replace(/-/g, " ")
                                      .replace(
                                        /(^\w{1})|(\s+\w{1})/g,
                                        (letter) => letter?.toUpperCase()
                                      )}
                                  </div>
                                </div>
                              </div>
                              <div className="item">
                                <div className="date-time">
                                  <div className="time">{item?.arrivetime}</div>
                                  <div className="date">
                                    {moment(item?.arrivaldate).format(
                                      "ddd, DD MMM YYYY"
                                    )}
                                  </div>
                                </div>
                                <div className="event">
                                  <div className="title">
                                    {CityConverter(item?.arrival)} (
                                    {item?.arrival})
                                  </div>
                                  <div className="description">
                                    {AirportConverter(item?.arrival)}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {index !==
                              secondChoose?.listflight?.flight?.length - 1 && (
                              <div className="w-100 text-center my-4">
                                <div className="flight">Transit</div>
                              </div>
                            )}
                          </div>
                        ))
                      ) : (
                        <div className="timeline-flight">
                          <div className="item">
                            <div className="date-time">
                              <div className="time">
                                {secondChoose?.listflight?.flight?.departtime}
                              </div>
                              <div className="date">
                                {moment(
                                  secondChoose?.listflight?.flight?.departdate
                                ).format("ddd, DD MMM YYYY")}
                              </div>
                            </div>
                            <div className="event">
                              <div className="title">
                                {CityConverter(
                                  secondChoose?.listflight?.flight?.fromcity
                                )}{" "}
                                ({secondChoose?.listflight?.flight?.fromcity})
                              </div>
                              <div className="description">
                                {AirportConverter(
                                  secondChoose?.listflight?.flight?.fromcity
                                )}
                              </div>
                              <div className="flight">
                                {AirlineImageConverter(
                                  firstChoose?.listflight?.flight?.airlinecode
                                )}
                                {secondChoose?.listflight?.flight?.flightno}
                                {" | "}
                                {filter?.cabin
                                  ?.replace(/-/g, " ")
                                  .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                                    letter?.toUpperCase()
                                  )}
                              </div>
                            </div>
                          </div>
                          <div className="item">
                            <div className="date-time">
                              <div className="time">
                                {secondChoose?.listflight?.flight?.arrivetime}
                              </div>
                              <div className="date">
                                {moment(
                                  secondChoose?.listflight?.flight?.arrivaldate
                                ).format("ddd, DD MMM YYYY")}
                              </div>
                            </div>
                            <div className="event">
                              <div className="title">
                                {CityConverter(
                                  secondChoose?.listflight?.flight?.tocity
                                )}{" "}
                                ({secondChoose?.listflight?.flight?.tocity})
                              </div>
                              <div className="description">
                                {AirportConverter(
                                  secondChoose?.listflight?.flight?.tocity
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
            </div>
          </div>
          <Button
            className="text-white w-100"
            onClick={() => setModal({ open: false, type: "" })}
          >
            Close
          </Button>
        </Modal.Body>
      </Modal>
    </Container>
  );
}

export default ListFlight;
