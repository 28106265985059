import React, { useContext, useEffect } from "react";
import { Button, Card, Container, Image } from "react-bootstrap";
import DesktopWrapper from "../../../layout/desktop/DesktopWrapper";
import FlyingImage from "../../../assets/icons/flying.svg";
import { Link, useNavigate } from "react-router-dom";
import UserContext from "../../../services/user";
import moment from "moment";
import { useTranslation } from "react-i18next";

function ReceiptDesktopPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data } = useContext(UserContext);

  useEffect(() => {
    if (data?.paymentDetail) {
      console.log(data?.paymentDetail);
    } else {
      navigate(-1);
    }
  }, []);

  return (
    <DesktopWrapper>
      <Container className="receipt py-4">
        <Card className="border-0 shadow-sm">
          <Card.Body className="p-5 text-center mobile-receipt">
            <h4 className="fw-bold pb-2">{t("booking_confirmation")}</h4>
            <h5 className="fw-light">
              {t("booking_id")} {data?.paymentDetail?.invoiceNumber}
            </h5>
            <div className="m-5">
              <Image src={FlyingImage} />
            </div>
            <h4 className="pb-3">{t("successful_booking")}</h4>
            <Card className="border-0 rounded-4 bg-light-primary mb-4">
              <Card.Body className="p-4 text-start">
                <div className="d-flex justify-content-between text-dark-grey fw-light pb-4">
                  <div>{t("date_transaction")}</div>
                  <div>
                    {moment(data?.paymentDetail?.createdAt).format("LLL")}
                  </div>
                </div>
                <div className="d-flex justify-content-between text-dark-grey fw-light pb-4">
                  <div>{t("payment_method")}</div>
                  {data?.paymentDetail?.paymentMethod === "bank_transfer" && (
                    <div>
                      {data?.paymentDetail?.paymentMethod?.toUpperCase() +
                        " " +
                        data?.paymentDetail?.va?.bank?.toUpperCase()}
                    </div>
                  )}
                  {data?.paymentDetail?.paymentMethod === "credit_card" && (
                    <div>
                      {data?.paymentDetail?.paymentMethod?.toUpperCase()}
                    </div>
                  )}
                  {(data?.paymentDetail?.paymentMethod === "gopay" ||
                    data?.paymentDetail?.paymentMethod === "shopeepay") && (
                    <div>
                      {data?.paymentDetail?.paymentMethod?.toUpperCase()}
                    </div>
                  )}
                  {data?.paymentDetail?.paymentMethod === "echannel" && (
                    <div>
                      {data?.paymentDetail?.paymentMethod?.toUpperCase() +
                        " " +
                        data?.paymentDetail?.bill?.bank?.toUpperCase()}
                    </div>
                  )}
                </div>
                {(data?.paymentDetail?.va ||
                  data?.paymentDetail?.paymentMethod === "echannel") && (
                  <div className="d-flex justify-content-between text-dark-grey fw-light pb-4">
                    <div>{t("va_number")}</div>
                    <div>
                      {data?.paymentDetail?.va?.vaNumber?.toUpperCase()}
                    </div>
                    {data?.paymentDetail?.paymentMethod === "echannel" && (
                      <div>
                        {data?.paymentDetail?.bill?.billerCode +
                          " " +
                          data?.paymentDetail?.bill?.billKey}
                      </div>
                    )}
                  </div>
                )}
                {data?.paymentDetail?.paymentMethod === "akulaku" && (
                  <div className="d-flex justify-content-between text-dark-grey fw-light pb-4">
                    <div>Link to Pay</div>
                    <div>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className="text-primary"
                        href={data?.paymentDetail?.cardlessUrl}
                      >
                        Pay Here
                      </a>
                    </div>
                  </div>
                )}
                {(data?.paymentDetail?.paymentMethod === "gopay" ||
                  data?.paymentDetail?.paymentMethod === "shopeepay") && (
                  <div className="d-flex justify-content-between text-dark-grey fw-light pb-4">
                    <div>Link to Pay</div>
                    <div>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className="text-primary"
                        href={
                          data?.paymentDetail?.eWalletActions?.length > 0 &&
                          data?.paymentDetail?.eWalletActions[1]?.url
                        }
                      >
                        Pay Here
                      </a>
                    </div>
                  </div>
                )}
                <div className="d-flex justify-content-between text-dark-grey fw-light pb-4">
                  <div>{t("price_paid")}</div>
                  <div className="text-black fw-normal">
                    {new Intl.NumberFormat("id-ID", {
                      style: "currency",
                      currency: "IDR",
                      minimumFractionDigits: 0,
                    }).format(data?.paymentDetail?.grossAmount)}
                  </div>
                </div>
                <hr></hr>
                <div className="fw-light mt-3">
                  <div className="pb-3">{t("ticket_price")}</div>

                  <div className="d-flex align-items-center justify-content-between fw-normal">
                    <div>{t("total_payment")}</div>
                    <div className="text-primary h6">
                      {" "}
                      {new Intl.NumberFormat("id-ID", {
                        style: "currency",
                        currency: "IDR",
                        minimumFractionDigits: 0,
                      }).format(data?.paymentDetail?.grossAmount)}{" "}
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
            <div className="d-flex align-items-center justify-content-between">
              <Button
                as={Link}
                to="/"
                variant="outline-primary"
                className="w-100 me-5"
              >
                {t("back_to_homepage")}
              </Button>
              <Button
                as={Link}
                to="/order-flight"
                variant="primary"
                className="text-white w-100 ms-5"
              >
                {t("view_order_details")}
              </Button>
            </div>
          </Card.Body>
        </Card>
      </Container>
    </DesktopWrapper>
  );
}
export default ReceiptDesktopPage;
