import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Image,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import DesktopWrapper from "../../../layout/desktop/DesktopWrapper";
import RelaxImage from "../../../assets/icons/relaxImage.svg";
import { Link, useNavigate } from "react-router-dom";
import UserContext from "../../../services/user";
import moment from "moment";
import { useTranslation } from "react-i18next";

function ReceiptDesktopPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data } = useContext(UserContext);
  const [dataBefore, setDataBefore] = useState({});

  useEffect(() => {
    if (data?.payment) {
      console.log(data?.payment);
      setDataBefore(data);
    } else {
      // navigate("/")
    }
  }, []);

  return (
    <DesktopWrapper>
      <Container className="receipt py-4">
        <Card className="border-0 shadow-sm">
          <Card.Body className="p-5 text-center mobile-receipt">
            <h4 className="fw-bold pb-2">{t("booking_confirmation")}</h4>
            <h5 className="fw-light">
              {t("booking_id")} {dataBefore?.payment?.invoiceNumber}
            </h5>
            <div className="m-5">
              <Image src={RelaxImage} />
            </div>
            <h4 className="pb-3">{t("successful_booking")}</h4>
            <Card className="border-0 rounded-4 bg-light-primary mb-4">
              <Card.Body className="p-4 text-start">
                <div className="d-flex justify-content-between text-dark-grey fw-light pb-4">
                  <div>{t("date_transaction")}</div>
                  <div>
                    {moment(dataBefore?.payment?.createdAt).format("LLL")}
                  </div>
                </div>
                <div className="d-flex justify-content-between text-dark-grey fw-light pb-4">
                  <div>{t("payment_method")}</div>
                  <div>
                    {dataBefore?.payment?.paymentMethod === "bank_transfer" && (
                      <div>
                        {dataBefore?.payment?.paymentMethod?.toUpperCase() +
                          " " +
                          dataBefore?.payment?.va?.bank?.toUpperCase()}
                      </div>
                    )}
                    {dataBefore?.payment?.paymentMethod === "credit_card" && (
                      <div>
                        {dataBefore?.payment?.paymentMethod?.toUpperCase()}
                      </div>
                    )}
                    {(dataBefore?.payment?.paymentMethod === "gopay" ||
                      dataBefore?.payment?.paymentMethod === "shopeepay") && (
                      <div>
                        {dataBefore?.payment?.paymentMethod?.toUpperCase()}
                      </div>
                    )}
                    {dataBefore?.payment?.paymentMethod === "echannel" && (
                      <div>
                        {dataBefore?.payment?.paymentMethod?.toUpperCase() +
                          " " +
                          dataBefore?.payment?.bill?.bank?.toUpperCase()}
                      </div>
                    )}
                  </div>
                </div>
                {(dataBefore?.payment?.paymentMethod === "bank_transfer" ||
                  dataBefore?.payment?.paymentMethod === "echannel") && (
                  <div className="d-flex justify-content-between text-dark-grey fw-light pb-4">
                    <div>{t("va_number")}</div>
                    <div>
                      {dataBefore?.payment?.va?.vaNumber?.toUpperCase()}
                    </div>
                    {dataBefore?.payment?.paymentMethod === "echannel" && (
                      <div>
                        {dataBefore?.payment?.bill?.billerCode +
                          " " +
                          dataBefore?.payment?.bill?.billKey}
                      </div>
                    )}
                  </div>
                )}
                {dataBefore?.payment?.paymentMethod === "akulaku" && (
                  <div className="d-flex justify-content-between text-dark-grey fw-light pb-4">
                    <div>Link to Pay</div>
                    <div>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className="text-primary"
                        href={dataBefore?.payment?.cardlessUrl}
                      >
                        Pay Here
                      </a>
                    </div>
                  </div>
                )}
                {(dataBefore?.payment?.paymentMethod === "gopay" ||
                  dataBefore?.payment?.paymentMethod === "shopeepay") && (
                  <div className="d-flex justify-content-between text-dark-grey fw-light pb-4">
                    <div>Link to Pay</div>
                    <div>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className="text-primary"
                        href={
                          dataBefore?.payment?.eWalletActions?.length > 0 &&
                          dataBefore?.payment?.eWalletActions[1]?.url
                        }
                      >
                        Pay Here
                      </a>
                    </div>
                  </div>
                )}
                <div className="d-flex justify-content-between text-dark-grey fw-light pb-4">
                  <div>{t("price_paid")}</div>
                  <div className="text-black fw-normal">
                    {new Intl.NumberFormat("id-ID", {
                      style: "currency",
                      currency: "IDR",
                      minimumFractionDigits: 0,
                    }).format(dataBefore?.payment?.grossAmount)}
                  </div>
                </div>
                <hr></hr>
                <div className="fw-light mt-3">
                  <div className="pb-3">{t("ticket_price")}</div>
                  <ul>
                    {dataBefore?.payment?.items?.map((item, index) => (
                      <li className="pb-2" key={index}>
                        <div className="d-flex justify-content-between">
                          <div>
                            {item?.name} (x{item?.quantity})
                          </div>
                          <div>
                            {new Intl.NumberFormat("id-ID", {
                              style: "currency",
                              currency: "IDR",
                              minimumFractionDigits: 0,
                            }).format(item?.total)}
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>

                  {dataBefore?.payment?.discount.length > 0 && (
                    <>
                      <div className="pb-3">{t("other_costs")}</div>
                      <ul>
                        {dataBefore?.payment?.discount?.map((item, index) => (
                          <li className="pb-2" key={index}>
                            <div className="d-flex justify-content-between">
                              <div>
                                {t("discount")} {item.name}
                              </div>
                              <div>
                                {new Intl.NumberFormat("id-ID", {
                                  style: "currency",
                                  currency: "IDR",
                                  minimumFractionDigits: 0,
                                }).format(item?.total)}{" "}
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}

                  <div className="d-flex align-items-center justify-content-between fw-normal">
                    <div>{t("total_payment")}</div>
                    <div className="text-primary h6">
                      {" "}
                      {new Intl.NumberFormat("id-ID", {
                        style: "currency",
                        currency: "IDR",
                        minimumFractionDigits: 0,
                      }).format(dataBefore?.payment?.grossAmount)}{" "}
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
            <div className="d-flex align-items-center justify-content-between">
              <Button
                as={Link}
                to="/"
                variant="outline-primary"
                className="w-100 me-5"
              >
                {t("back_to_homepage")}
              </Button>
              <Button
                as={Link}
                to="/order"
                variant="primary"
                className="text-white w-100 ms-5"
              >
                {t("view_order_details")}
              </Button>
            </div>
          </Card.Body>
        </Card>
      </Container>
    </DesktopWrapper>
  );
}
export default ReceiptDesktopPage;
